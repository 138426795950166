
.Toastify__toast-container{

    z-index: 11005 !important;

}

.notify-success{

    background-color: darkgreen; 
    width: 100vh; 
    text-align: center;
    height: 10rem;
    font-size: 1.5rem;
    bottom: -3vh;
    position: absolute;
    z-index: 11005;

  
    
}

@media only screen and (max-width: 900px) {
    .notify-success {
        width: 100%;
    }
}

.notify-warn{

    background-color: #ffcc66; 
    width: 100vh; 
    bottom: -3vh;
    text-align: center;
    height: 10rem;
    font-size: 1.5rem;
    color: red;
    position: absolute;
    z-index: 11005;
    
}

@media only screen and (max-width: 900px) {
    .notify-warn {
        width: 100%;
    }
}

.notify-info{

    width: 121rem; 
    text-align: center;
    height: 5rem;
    font-size: 1.5rem;
    position: absolute;
    bottom: -3vh;
    z-index: 11005;
    
}

@media only screen and (max-width: 900px) {
    .notify-info {
        width: 100%;
    }
}

.notify-error{

    width: 121rem; 
    text-align: center;
    height: 5rem;
    font-size: 1.5rem; 
    z-index: 11005;
    position: absolute;
    bottom: -3vh;
    
}

@media only screen and (max-width: 900px) {
    .notify-error {
        width: 100%;
    }
}